import * as React from 'react'

import { cn } from '~/utils/index.ts'

export type Props<T extends React.ElementType> = {
	as?: T
	color?: 'light' | 'dark' | 'key'
} & React.ComponentPropsWithoutRef<T>

export function Typography<T extends React.ElementType = 'p'>({
	as,
	color = 'dark',
	className,
	...props
}: Props<T>) {
	const Component = as || 'p'
	return (
		<Component
			className={cn(
				{
					'font-sans text-base leading-relaxed': Component === 'p',
					'font-serif text-heading1 font-medium leading-tight':
						Component === 'h1',
					'font-serif text-heading2 font-medium leading-normal':
						Component === 'h2',
					'font-serif text-heading3 font-medium leading-normal':
						Component === 'h3',
					'text-black': color === 'dark',
					'text-white': color === 'light',
					'text-key': color === 'key',
				},
				className,
			)}
			{...props}
		/>
	)
}
