import * as React from 'react'
import { motion, useReducedMotion } from 'framer-motion'

import { Image } from '~/components/Image.tsx'

type Props = {
	content: React.ReactNode | React.ReactNode[]
	img: {
		webp: string
		png: string
		webpSm?: string
		pngSm?: string
		alt?: string
	}
}

export function Hero({ content, img }: Props) {
	const reducedMotion = useReducedMotion()
	return (
		<section id="hero">
			<div className="mx-auto grid h-auto min-h-[calc(100vh-6rem)] grid-rows-[auto_1fr] gap-5 px-8 py-6 lg:h-[calc(100vh-6rem)] lg:grid-cols-2 lg:grid-rows-1">
				<div className="grid place-items-center">{content}</div>
				<motion.div
					initial={{ opacity: 0.75, scale: 0.75 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{
						duration: reducedMotion ? 0 : 0.5,
					}}
					className="-order-1 grid origin-bottom place-items-center lg:order-2"
				>
					<div className="outline-box text-key">
						<Image
							{...img}
							className="aspect-[3/4] h-[calc(50vh-6rem)] w-full object-cover shadow-hero lg:h-[calc(90vh-6rem)]"
							width={1200}
							height={1600}
							sizes="(min-width: 1024px) 50vh, 90vh"
						/>
					</div>
				</motion.div>
			</div>
		</section>
	)
}
