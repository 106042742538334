import * as React from 'react'

type Props = {
	webp: string
	png: string
	webpSm?: string
	pngSm?: string
} & React.ComponentPropsWithoutRef<'img'>

export function Image({ webp, png, webpSm, pngSm, alt = '', ...props }: Props) {
	return (
		<picture>
			{webpSm ? (
				<source srcSet={webpSm} type="image/webp" media="(max-width: 768px)" />
			) : null}
			{pngSm ? (
				<source srcSet={pngSm} type="image/jpeg" media="(max-width: 768px)" />
			) : null}
			<source srcSet={webp} type="image/webp" />
			<source srcSet={png} type="image/jpeg" />
			<img src={png} alt={alt} {...props} />
		</picture>
	)
}
